<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>Создать ведемость</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="date" rules="required">
                <v-menu ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="statementDate|yearMonth" label="Дата" name="date"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="statementDate" @input="dateMenu = false"
                                 type="month"
                                 :allowed-dates="allowedMonths"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon>Создать</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    name: 'salary-statement-form',

    data() {
      return {
        dateMenu: false,
      }
    },

    computed: {
      statementDate: {
        get() {
          return `${this.model.year}-${this.model.month}-01`
        },
        set(val) {
          let [year, month] = val.split('-')

          this.setModel({
            id: this.model.id,
            year,
            month
          })
        }
      },
      ...mapState('SalaryStatement', ['saving', 'model']),
    },

    methods: {
      ...mapMutations('SalaryStatement', ['setModel']),

      allowedMonths(val) {
        return true
      },

      cancel(reset) {
        reset()
        this.$emit('salary-statement:form:cancel')
      },

      save() {
        this.$store
            .dispatch('SalaryStatement/create')
            .then(() => {
              this.$emit('salary-statement:form:saved')
            })
      },
    },
  }
</script>
