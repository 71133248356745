<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="12" class="d-flex align-center pb-0">
        <span class="text-h5">
          <v-icon color="black" left class="pb-1">mdi-hammer-wrench</v-icon> Дневные объемы работ
        </span>
      </v-col>
    </v-row>

    <v-data-table :items="items" :headers="nonSortableHeaders"
                  :loading="!!fetchingVolumes" :items-per-page="filter.limit"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.reg_date|date}}</td>
          <td>{{item.total|money}}</td>
          <td class="text-center">{{item.productjobvolume_set.length}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon> Показать
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" width="70vw" v-model="detailDialog">
      <job-volume-details :model="item" @dialog:close="closeDetails"/>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('SalaryStatement')

  import JobVolumeDetails from '@/modules/salary-statements/job-volume-details'
  import {hasDialog, hasFilter} from '@/mixins'

  export default {
    name: 'job-volume-list',

    mixins: [hasFilter, hasDialog],

    data() {
      return {
        item: null,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState({
        fetchingVolumes: 'fetchingVolumes',
        data: 'volumes',
      }),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Дата', width: '170'},
          {text: 'Сумма', width: '200'},
          {text: 'Кол-во элементов', align: 'center'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      items() {
        return this.data?.results || []
      },
    },

    methods: {
      showDetails(item) {
        this.item = item
        this.detailDialog = true
      },

      closeDetails() {
        this.item = null
        this.detailDialog = false
      },

      ...mapActions({
        fetch: 'fetchVolumes',
      }),
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Дневные объемы работ')
    },

    components: {
      JobVolumeDetails,
    }
  }
</script>
