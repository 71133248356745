<template>
  <v-card>
    <v-card-title>
      Дневной объём работ за {{model.reg_date|date}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-1">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">#</th>
            <th>Продукт</th>
            <th class="text-center">Кол-во</th>
            <th class="text-right">На сумму</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in model.productjobvolume_set" :key="i">
              <td class="text-center">{{ i + 1 }}</td>
              <td>{{ row.product.product_type.name | money }}</td>
              <td class="text-center">{{ row.product.amount }}</td>
              <td class="text-right">{{row.amount|money}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-right text-h4">
        Итог: {{model.total|money}}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'volume-sharing-details',

  props: {
    model: {
      required: true
    }
  },
}
</script>
