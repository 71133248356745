<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить курс валюты' : 'Создать курс валюты'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>текущая дата: {{now|dateTime}}</p>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="rate" rules="required|double">
                <v-text-field v-model="model.rate"
                              required :error-messages="errors"
                              label="Курс" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'currency-rates-form',

    computed: {
      ...mapState('CurrencyRate', ['saving', 'model']),

      now() {
        return moment()
      }
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('currency-rates:form:cancel')
      },

      save() {
        this.$store
          .dispatch('CurrencyRate/save')
          .then(() => {
            this.$emit('currency-rates:form:saved')
          })
      },
    },
  }
</script>
