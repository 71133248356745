<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="4" class="d-flex align-center">
        <span class="text-h5">Список затрат</span>
      </v-col>
      <v-col cols="2" :offset="filter.source ? 0 : 2">
        <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.source" :items="SourceTypes.getFor('credit')"
                  item-value="id" item-text="name"
                  label="Тип объекта" clearable
                  @change="resetSourceId()" @click:clear="resetSourceId()" hide-details
        ></v-select>
      </v-col>
      <v-col cols="2" v-if="filter.source && getSourceType(filter.source).hasData">
        <v-select v-model="object" :items="objects.data"
                  item-value="id" item-text="full_name"
                  :label="objectLabel" prepend-inner-icon="mdi-view-dashboard"
                  :loading="!!objects.loading" :disabled="!!fetching"
                  clearable @click:clear="object = null" hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" class="mt-1">
        <v-row class="font-weight-bold blue-grey lighten-5 body-1">
          <v-col cols="2">Наличными:</v-col>
          <v-col cols="2" class="text-left">
            <div class="success--text text--darken-3">Приход: {{totals.uzs_cash_debet_total|money}}</div>
            <div class="error--text text--darken-2">Расход: {{totals.uzs_cash_credit_total|money}}</div>
          </v-col>
          <v-col cols="2" class="text-left">
            <div class="success--text text--darken-3">Приход: {{totals.usd_cash_debet_total|money('usd')}}</div>
            <div class="error--text text--darken-2">Расход: {{totals.usd_cash_credit_total|money('usd')}}</div>
          </v-col>
          <v-col cols="2">Перечислением:</v-col>
          <v-col cols="2" class="text-left">
            <div class="success--text text--darken-3">Приход: {{totals.uzs_transfer_debet_total|money}}</div>
            <div class="error--text text--darken-2">Расход: {{totals.uzs_transfer_credit_total|money}}</div>
          </v-col>
          <v-col cols="2" class="text-left">
            <div class="success--text text--darken-3">Приход: {{totals.usd_transfer_debet_total|money('usd')}}</div>
            <div class="error--text text--darken-2">Расход: {{totals.usd_transfer_credit_total|money('usd')}}</div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="expenses"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{ filter.limit * (filter.page - 1) + index + 1 }}</td>
          <td>{{ item.payment.reg_date|date }}</td>
          <td>{{ item.payment.reg_number }}</td>
          <td>{{getPaymentForm(item.payment.cash_type).name}}</td>
          <td>
            {{ getSourceType(item.payment.source).name }}
          </td>
          <td>{{item.payment.total|money(item.payment.currency)}}</td>
          <td>
            <span v-if="item.payment.source === 'transport_expenses'">
              {{item.payment.source_object_info.number}} {{item.payment.source_object_info.model}} {{item.payment.comments}}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
import {mapState as mapStateDefault, mapGetters, createNamespacedHelpers} from 'vuex'

const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Expense')

import moment from 'moment'
import {USER_DATE_FORMAT, SERVER_DATE_FORMAT} from '@/filters'
import {hasFilter, hasDialog} from '@/mixins'

export default {
  name: 'expense-list',

  mixins: [hasFilter, hasDialog],

  customFilters: {
    reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
    reg_date_before: moment().format(SERVER_DATE_FORMAT),
    reg_number: '',
    source: null,
    counteragent: null,
    staff: null,
    owner: null,
  },

  created() {
    this.fetch(this.filter)
  },

  computed: {
    ...mapState([
      'fetching',
      'data',
    ]),
    ...mapStateDefault('Payment', [
      'SourceTypes'
    ]),

    ...mapStateDefault('Counteragent', {
      counteragents: state => state.data?.results || [],
      counteragentsLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Staff', {
      staff: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
        return s
      }),
      staffLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Owner', {
      owner: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
        return s
      }),
      ownerLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Transport', {
      transport: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.number} - ${s.model}`
        return s
      }),
      transportLoading: state => !!state.fetching,
    }),
    ...mapGetters('Counteragent', ['getCounteragent']),
    ...mapGetters('Staff', ['getStaff']),
    ...mapGetters('Owner', ['getOwner']),
    ...mapGetters('Transport', ['getTransport']),
    ...mapGetters('Payment', ['getSourceType', 'getPaymentForm']),

    headers() {
      return [
        {text: '#', align: 'center', width: '100'},
        {text: 'Дата', width: '150px'},
        {text: 'Номер регистрации', width: '170px'},
        {text: 'Форма', width: '100px'},
        {text: 'Тип'},
        {text: 'Сумма'},
        {text: 'Детали'},
      ]
    },

    expenses() {
      return this.data?.results || []
    },

    fromDateFormatted() {
      return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
    },

    toDateFormatted() {
      return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
    },

    objects() {
      switch (this.filter.source) {
        case 'counteragent':
          return {data: this.counteragents, loading: this.counteragentsLoading, title: 'full_name', field: 'counteragent'}
        case 'staff_salary':
          return {data: this.staff, loading: this.staffLoading, title: 'full_name', field: 'staff'}
        case 'capital':
          return {data: this.owner, loading: this.ownerLoading, title: 'full_name', field: 'owner'}
        case 'transport_expenses':
          return {data: this.transport, loading: this.transportLoading, title: 'full_name', field: 'owner'}
        default:
          return {}
      }
    },

    object: {
      get() {
        return this.filter[this.objects.field]
      },
      set(val) {
        this.filter[this.objects.field] = val
        this.resetSourceId(this.SourceTypes.getIds().filter(f => f !== this.objects.field))
      }
    },

    objectLabel() {
      return (this.SourceTypes.find(s => s.id === this.filter.source) || {}).object_label
    },

    totals() {
      return this.data?.total || {}
    },
  },

  methods: {
    resetSourceId(fields) {
      (fields || this.SourceTypes.getIds()).forEach(field => {
        this.filter[field] = null
      })
    },

    ...mapActions(['fetch']),
    ...mapMutations(['setModel']),

    guessSource(item) {
      return item.payment.source || (item.payment.counteragent && 'counteragent') || (item.payment.staff && 'staff_salary') || (item.payment.capital && 'capital')
    },
  },

  mounted() {
    this.$eventBus.$emit('setTitle', 'Список затрат')
    this.$store.dispatch('Counteragent/fetch')
    this.$store.dispatch('Staff/fetch')
    this.$store.dispatch('Owner/fetch')
    this.$store.dispatch('Transport/fetch')
  },
}
</script>
