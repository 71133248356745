<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Архив курсов валют</span>
        <v-dialog v-if="userService.can('currency_rate_add_button')"  v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <currency-rate-form v-if="formDialog"
                               @currency-rates:form:saved="closeForm"
                               @currency-rates:form:cancel="closeForm"
          ></currency-rate-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table :items="currencyRates"
                  :headers="headers" :loading="!!fetching" :items-per-page="filter.limit"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.updated_at|dateTime}}</td>
          <td>{{item.rate|money}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('CurrencyRate')
  import CurrencyRateForm from '@/modules/currency-rates/form'
  import {hasFilter} from '@/mixins'

  export default {
    name: 'currency-rates-list',

    mixins: [hasFilter],

    data() {
      return {
        formDialog: false,
        detailDialog: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'current',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100px'},
          {text: 'Дата', width: '200px'},
          {text: 'Курс обмена'},
        ]
      },

      currencyRates() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch']),
      ...mapMutations(['setModel']),

      showForm(item) {
        this.formDialog = true
        this.setModel(item)
      },

      closeForm() {
        this.formDialog = false
        this.setModel({})
        this.fetch(this.filter)
      },

      askRemove(item) {
        if (confirm(`Вы уверены удалить ${item.name}`)) {
          this.remove(item.id)
        }
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Архив курсов валют')
    },

    components: {
      CurrencyRateForm,
    },
  }
</script>
