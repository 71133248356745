<template>
  <v-form>
    <validation-observer v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>Установить остаток с пред периода cотрудника</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h4 class="mb-3">Сотрудник: {{item.staff|full_name}}</h4>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="date" rules="required">
                <v-text-field v-model="model.remain_start"
                              label="Сумма" name="remain_start"
                              :disabled="saving" type="number" clearable hide-details
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon>Создать</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import Vue from "vue";

  export default {
    name: 'start-remaining-balance-form',

    props: {
      item: {
        type: Object,
        required: true,
      }
    },

    data() {
      return {
        model: {},
        saving: false,
      }
    },

    created() {
      this.model = {
        id: this.item.id,
        remain_start: this.item.remain_start,
      }
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('dialog:close')
      },

      save() {
        if (!this.saving) {
          this.saving = true
          this.$store.dispatch('SalaryStatement/setStartBalance', this.model)
            .then(() => {
              this.$eventBus.$emit('notification:success', 'Изменения сохранены')
              this.$emit('dialog:close')
            })
            .finally(() => {
              this.saving = false
            })
        }
      },
    },
  }
</script>
