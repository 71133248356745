<template>
  <v-card :loading="!!fetching">
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>
    <v-card-title>
      Зарплатная ведемость от {{model.date|yearMonth}}
      <template v-if="!fetching">
        <div class="ml-3">
          <v-btn-toggle v-model="staffMode" color="primary" dense rounded>
            <v-btn small value="">
              <v-icon small>mdi-account-group</v-icon> Все
            </v-btn>
            <v-btn small value="daily">
              <staff-work-type type="daily" with-title/>
            </v-btn>
            <v-btn small value="volume">
              <staff-work-type type="volume" with-title/>
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-menu ref="menu" v-model="dateFilterMenu"
                :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                :return-value.sync="filter.dates"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="dateFilterText" class="ml-3"
                          label="Даты" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker no-title scrollable range v-model="filter.dates">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateFilterMenu = false">Отмена</v-btn>
            <v-btn text color="primary" @click="saveFilters">Фильтровать</v-btn>
          </v-date-picker>
        </v-menu>
      </template>

      <v-spacer></v-spacer>

      <template v-if="!fetching">
        <div class="mr-3">
          <span class="text-body-2 pr-2">Вид:</span>
          <v-btn-toggle v-model="viewMode" color="primary" dense rounded>
            <v-btn small value="toggler">
              <v-icon small left>mdi-check-circle</v-icon> Галочки
            </v-btn>
            <v-btn small value="numbers">
              <v-icon small left>mdi-numeric</v-icon> Цифры
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-btn v-if="canEdit" @click="save" color="success" small class="mr-4"
               :loading="!!saving"
               :disabled="!!saving">
          <v-icon>mdi-save</v-icon> Cохранить
        </v-btn>
        <v-btn icon @click="!saving && $emit('dialog:close')">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text class="px-1">
      <div v-if="!!fetching" class="text-center pa-5">
        <v-progress-circular indeterminate class="pa-4 text-center" width="2"/>
      </div>
      <v-simple-table v-else class="salary-statement-details" fixed-header :height="dataTableHeight">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="fixed text-left">Сотрудник</th>
            <th class="fixed fixed-2 text-сenter">Оклад, сум</th>
            <th class="fixed fixed-3 text-сenter">Остаток пред</th>
            <th class="fixed fixed-4 text-сenter">Отработано</th>
            <th class="fixed fixed-5 text-сenter">Заработано</th>
            <th class="fixed fixed-6 text-сenter">Оплачено</th>
            <th class="fixed fixed-7 text-сenter">Остаток тек</th>
            <th v-for="el in model.rows[0].staffsalarydate_set" class="staff-day px-0">
              <div class="px-4">{{el.date|monthDay}}</div>
              <a v-if="getRetainedVolume(el.date)" class="d-block" @click="showVolumeSharingDialog(el.date)">
                <span v-if="needsToBeShared(el.date) === 0" class="d-block success--text">
                  <i class="mr-1 mdi mdi-check-all"></i>{{ getRetainedVolume(el.date) | money }}
                </span>
                <template v-else>
                  <span class="d-block info--text">
                    <i class="mr-1 mdi mdi-cash-multiple"></i>{{ getRetainedVolume(el.date) | money }}
                  </span>
                  <span class="d-block warning--text"><i class="mr-1 mdi mdi-alert-decagram-outline"></i>{{ needsToBeShared(el.date) | money }}</span>
                </template>
              </a>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, i) in rows" :key="row.id"
              class="text-center" :class="{hasVolume: (row.staff).type === 'volume', 'hovered-tr': hoveredLine === row.id}"
          >
            <td class="fixed text-left">
                {{ i + 1 }}.
                <span class="link-like" @click="showStaff(row.staff)">
                  <staff-work-type :type="(row.staff).type"/> {{row.staff | full_name}}
                </span>
            </td>
            <td class="fixed fixed-2 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >{{ row.staff.salary_rate | money(null, 0, false) }}</td>
            <td class="fixed fixed-3 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >
              <span class="pl-4 link-like" @click="showStartBalanceFormDialog(row)">{{ row.remain_start | money(null, 0, false) }}</span>
            </td>
            <td class="fixed fixed-4 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >{{ row.worked_days || 0 }}</td>
            <td class="fixed fixed-5 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >{{ row.credit || 0 | money(null, 0, false) }}</td>
            <td class="fixed fixed-6 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >{{ row.debet || 0 | money(null, 0, false) }}</td>
            <td class="fixed fixed-7 text-right"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >{{ row.remain_end | money(null, 0, false) }}</td>
            <td v-for="day in row.staffsalarydate_set" :key="day.id"
                class="staff-day text-center pa-0"
                @mouseenter="hoveredLine = row.id"
                @mouseleave="hoveredLine = null"
            >
              <span v-if="viewMode === 'toggler'"
                    @click="canEdit && (row.staff.type === 'volume' ? showVolumeSharingDialog(day.date) : toogleStatus(day))"
              >
                <i v-if="day.is_active && day.salary_amount !== 0"
                   class="v-icon mdi mdi-check-circle theme--light"
                   :class="!canEdit ? 'gray--text' : 'success--text'"
                />
                <i v-else class="v-icon mdi mdi-checkbox-blank-circle-outline theme--light gray--text"/>
              </span>
              <span v-else
                    @click="canEdit && (row.staff.type === 'volume' ? showVolumeSharingDialog(day.date) : toogleStatus(day))"
              >
                <span v-if="day.is_active">{{day.salary_amount | money(null, 0, false)}}</span>
                <span v-else class="px-4 py-2">-</span>
              </span>
            </td>
          </tr>
          <tr class="sticky-footer">
            <td class="fixed text-left font-weight-black">Итог</td>
            <td class="fixed fixed-2 text-right">{{ rows.reduce((acc, row) => acc + row.staff.salary_rate, 0) | money(null, 0, false) }}</td>
            <td class="fixed fixed-3 text-right">{{ rows.reduce((acc, row) => acc + row.remain_start, 0) | money(null, 0, false) }}</td>
            <td class="fixed fixed-4 text-right">{{ rows.reduce((acc, row) => acc + row.worked_days, 0) }}</td>
            <td class="fixed fixed-5 text-right">{{ rows.reduce((acc, row) => acc + (row.credit || 0), 0) | money(null, 0, false) }}</td>
            <td class="fixed fixed-6 text-right">{{ rows.reduce((acc, row) => acc + (row.debet || 0), 0) | money(null, 0, false) }}</td>
            <td class="fixed fixed-7 text-right">{{ rows.reduce((acc, row) => acc + row.remain_end, 0) | money(null, 0, false) }}</td>
            <td v-for="day in model.rows[0].staffsalarydate_set" :key="day.id"
                class="staff-day text-center pa-0"
            >
              {{ rows.reduce((acc, el) => {
                return acc + (el.staffsalarydate_set.find(e => e.is_active && e.date === day.date) || {salary_amount: 0}).salary_amount
              }, 0) | money(null, 2, false) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="volumeSharingDialog" width="70vw">
        <volume-sharing-form v-if="volumeSharingDialog"
                             :is-closed="!canEdit"
                             :date="volumeDate"
                             :rows="rows.filter(r => r.staff.type === 'volume')"
                             @dialog:close="closeVolumeSharingDialog"
        ></volume-sharing-form>
      </v-dialog>
      <v-dialog v-model="showStartBalanceForm" width="40vw">
        <start-balance-form v-if="showStartBalanceForm"
                            :item="startBalanceElement"
                            @dialog:close="closeStartBalanceFormDialog"
        ></start-balance-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers, mapState as globalMapState} from 'vuex'
  import moment from 'moment'
  const {mapState} = createNamespacedHelpers('SalaryStatement')
  import {SERVER_DATE_FORMAT} from '@/filters'

  import VolumeSharingForm from './volume-sharing-form'
  import StartBalanceForm from './start-balance-form'

  export default {
    name: 'salary-statement-details',

    props: {
      id: {
        type: Number,
        required: true,
      },
      defaultDate: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        dateFilterMenu: false,

        staffMode: '',
        viewMode: 'toggler',

        volumeSharingDialog: false,
        volumeDate: '',

        showStartBalanceForm: false,
        startBalanceElement: {},

        filter: {
          dates: [
            this.defaultDate,
            this.defaultDate,
          ],
        },

        updates: {
          attended_employees: [],
          absent_employees: [],
        },

        hoveredLine: null,

        dataTableExtraOffset: 48,
      }
    },

    computed: {
      canEdit() {
        return this.userService.can('salary_month_statement_changes') && this.model.id && !this.model.is_closed
      },

      dateFilterText() {
        let dates = this.filter.dates.slice().sort()
        let str = this.$options.filters.date(dates[0])

        if (dates[0] === dates[1]) {
          return str
        }

        return str + ' - ' + this.$options.filters.date(dates[1])
      },

      rows() {
        return (this.model.rows || []).filter(row => row.staff?.id && (!this.staffMode || row.staff.type === this.staffMode))
      },
      ...mapState(['fetching', 'saving', 'model', 'volumes']),
      ...globalMapState(['appSettings']),
    },

    methods: {
      needsToBeShared(date) {
        return this.getRetainedVolume(date) - this.model.rows.filter(r => r.staff.type === 'volume').reduce((acc, el) => {
          let cell = el.staffsalarydate_set.find(e => e.date === date)
          return acc + (cell.is_active ? cell.salary_amount : 0)
        }, 0)
      },

      showVolumeSharingDialog(date) {
        this.volumeDate = date
        this.volumeSharingDialog = true
      },

      closeVolumeSharingDialog(payload) {
        this.volumeDate = ''
        this.volumeSharingDialog = false
        if (!payload.noChanges) {
          this.fetch()
        }
      },

      showStartBalanceFormDialog(row) {
        if (this.appSettings.enableStartRemainBalanceForm) {
          this.startBalanceElement = row
          this.showStartBalanceForm = true
        }
      },

      closeStartBalanceFormDialog() {
        this.startBalanceElement = {}
        this.showStartBalanceForm = false
        this.fetch()
      },

      getRetainedVolume(date) {
        return (this.volumes || {}).results?.find(v => v.reg_date === date)?.total || 0
      },

      toogleStatus(day) {
        let to = 'attended_employees'
        let from = 'absent_employees'

        if (day.is_active) {
          to = 'absent_employees'
          from = 'attended_employees'
        }

        this.updates[to].push(day.id)
        let pos = this.updates[from].indexOf(day.id)
        if (pos !== -1) {
          this.updates[from].splice(pos, 1)
        }

        day.is_active = !day.is_active
      },

      save() {
        this.$store.dispatch('SalaryStatement/update', this.updates)
                   .then(() => this.fetch())
      },

      fetch() {
        this.$store.dispatch('SalaryStatement/show', {
          id: this.id,
          date_from: this.filter.dates[0],
          date_to: this.filter.dates[1]
        })
      },

      saveFilters() {
        this.$refs.menu.save(this.filter.dates)
        this.filter.dates.sort()
        this.dateFilterMenu = false
        this.fetch()
      },

      showStaff(staff) {
        const routeData = this.$router.resolve({
          name: 'staff.list',
          query: {
            staff_id: staff.id,
            date_after: moment(this.filter.dates[0]).startOf('month').format(SERVER_DATE_FORMAT),
            date_before: moment(this.filter.dates[1]).endOf('month').format(SERVER_DATE_FORMAT),
          }
        })
        window.open(routeData.href, '_blank');
      },
    },

    created() {
      this.fetch()
    },

    components: {
      VolumeSharingForm,
      StartBalanceForm,
    }
  }
</script>

<style scoped>
.fixed {
  position: sticky;
  background: white;
  left: 0;
  top: auto;
  z-index: 5;
  border-right: 1px solid #eee;
}
th.fixed {
  z-index: 6!important;
}
th.text-сenter {
  text-align: center!important;
}

.fixed-2 {
  left: 220px;
  background: #e5e8f7;
}
.fixed-3 {
  left: 315px;
  background: #e5e8f7;
}
.fixed-4 {
  left: 410px;
  background: #e5e8f7;
}
.fixed-5 {
  left: 505px;
  background: #e5e8f7;
}
.fixed-6 {
  left: 600px;
  background: #e5e8f7;
}
.fixed-7 {
  left: 695px;
  background: #e5e8f7;
}

td:nth-child(n+8),
th:nth-child(3) {
  padding: 0!important;
}
th:first-child,
td:first-child {
  min-width: 220px !important;
}
th,
td {
  padding-left: 8px !important;
  padding-right: 8px !important;
  max-height: 36px !important;
  height: 36px !important;
}

th:nth-child(4),
td:nth-child(4) {
  text-align: center;
}
td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
td:nth-child(6),
td:nth-child(7) {
  text-align: right;
  min-width: 95px;
}

td i {
  cursor: pointer;
}

tr.hasVolume,
td.fixed.hasVolume {
  background: rgba(242, 239, 253, 0.55);
}
.staff-day {
  min-width: 100px;
  text-align: center!important;
  border-left: 1px solid #eee;
}
.staff-day span {
  cursor: pointer;
}
.hovered-tr td {
  background: #eee;
}
.sticky-footer {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  z-index: 6;
}
.sticky-footer td {
  border-top: 1px solid #bbb;
  background: #bec2da;
}
</style>
