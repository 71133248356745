<template>
  <v-card>
    <v-card-title>
      Распределение дневного объёма за {{date|date}}
      <v-spacer></v-spacer>
      <v-btn icon @click="!savingVolumes && $emit('dialog:close', {noChanges: !currentVolume})">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-1">
      <template v-if="currentVolume">
        <v-row>
          <v-col cols="8">
            <table class="w-100">
              <thead>
              <tr>
                <th class="text-center pl-3">#</th>
                <th class="text-left pa-3 pl-1">Сотрудник</th>
                <th class="text-right pa-3">Оклад, сум</th>
                <th class="text-right pa-3" style="width: 170px;">Распределение, сум</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, i) in rows" :key="i">
                <td class="pl-3">{{ i+1 }}</td>
                <td class="pa-3 pl-1">{{ row.staff | full_name }}</td>
                <td class="text-right pa-3">{{ row.staff.salary_rate | money }}</td>
                <td class="text-right">
                  <validation-provider v-slot="{errors}" :name="'volume.' + i">
                    <v-text-field :value="getVolume(row)"
                                  @keyup="setVolume(row)"
                                  required dense class="text-right" :disabled="isClosed"
                                  :error-messages="errors" suffix="cум"
                    ></v-text-field>
                  </validation-provider>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="4" id="volume-sharing-totals" class="text-right pt-6 pr-5">
              <h2 class="text--primary">Общая сумма</h2>
              <p class="headline mb-0">{{currentVolume.total | money}}</p>
              <h2 class="text--primary mt-3">Распределено</h2>
              <p class="headline mb-0">{{sharedVolume | money}}</p>
              <h2 class="text--primary mt-3">Остаток</h2>
              <p class="headline font-weight-black mb-0"
                 :class="currentVolume.total - sharedVolume < 0 ? 'error--text' : (currentVolume.total - sharedVolume === 0 ? 'success--text' : 'warning--text')"
              >{{currentVolume.total - sharedVolume | money}}</p>
          </v-col>
        </v-row>
        <v-card-actions v-if="!isClosed" class="text-right mt-8">
          <v-spacer/>
          <v-btn color="success" rounded :disabled="currentVolume.total - sharedVolume < 0 || !!savingVolumes"
                 @click="save"
          >
            <v-icon small>mdi-content-save-outline</v-icon> Сохранить
          </v-btn>
        </v-card-actions>
      </template>
      <div class="text-center" v-else>
        Нет данных для распределения на эту дату
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('SalaryStatement')

export default {
  name: 'volume-sharing-form',

  props: {
    date: {
      required: true,
    },

    rows: {
      type: Array,
      default() {
        return []
      }
    },
    isClosed: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      updates: {},
    }
  },

  computed: {
    ...mapState(['volumes', 'savingVolumes']),

    currentVolume() {
      return (this.volumes || {}).results?.find(v => v.reg_date === this.date)
    },

    sharedVolume() {
      return this.rows.reduce((acc, r) => +this.getVolume(r) + acc, 0)
    }
  },

  methods: {
    getVolume(row) {
      let el = this._getCell(row)
      return el.is_active && el.salary_amount ? el.salary_amount : ''
    },

    setVolume(row) {
      let el = this._getCell(row)
      let val = Math.abs(event.currentTarget.value.replace(/[^0-9]/g, ''))

      el.salary_amount = val
      el.is_active = val !== 0
    },

    _getCell(row) {
      return row.staffsalarydate_set.find(ss => ss.date === this.date)
    },

    save() {
      let data = this.rows.reduce((coll, row) => {
        coll.push({
          id: this._getCell(row).id,
          salary_amount: +this.getVolume(row),
        })
        return coll
      }, [])

      this.$store.dispatch('SalaryStatement/updateVolumes', data)
          .then(() => this.$emit('dialog:close'))
    },
  },
}
</script>

<style>
.v-text-field.text-right input {
  text-align: right;
}
#volume-sharing-totals {
  height: 100%;
  position: sticky;
  top: 0;
}
</style>
