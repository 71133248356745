<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="12" class="d-flex align-center pb-0">
        <span class="text-h5">
          <v-icon color="black" left class="pb-1">mdi-account-cash-outline</v-icon> Зарплатные ведемости
        </span>
        <template v-if="userService.can('salary_month_statement_changes')">
          <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                 @click="showForm"
          >
            <v-icon left>mdi-plus</v-icon> Добавить
          </v-btn>
          <v-dialog v-model="formDialog" width="300px" persistent>
            <salary-statement-form v-if="formDialog"
                                   @salary-statement:form:saved="closeForm"
                                   @salary-statement:form:cancel="closeForm"
            ></salary-statement-form>
          </v-dialog>
        </template>
      </v-col>
    </v-row>

    <v-data-table :items="salaryStatements" :headers="nonSortableHeaders"
                  :loading="!!fetching" :items-per-page="filter.limit"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>
            {{item.date|yearMonth}}
            <v-icon v-if="item.is_closed" x-small class="mb-1">mdi-lock</v-icon>
          </td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetailsDialog(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <template v-if="userService.can('salary_month_statement_changes')">
              <v-btn class="ml-2" text small depressed
                     v-if="!item.is_closed"
                     :color="item.is_closed ? 'info' : 'pink lighten-1'"
                     @click="toggleStateHandler(item)"
              >
                <v-icon left small>
                  {{item.is_closed ? 'mdi-lock-open' : 'mdi-lock'}}
                </v-icon>
                {{item.is_closed ? 'Открыта' : 'Закрыть'}}
              </v-btn>
              <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                     :loading="deleting[item.id]"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" fullscreen v-model="detailDialog">
      <salary-statement-detail :id="activeDetailId" :default-date="activeDetailDefaultDate" @dialog:close="closeDetails"></salary-statement-detail>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import {createNamespacedHelpers} from 'vuex'
  import {SERVER_DATE_FORMAT} from '@/filters'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('SalaryStatement')

  import SalaryStatementDetail from '@/modules/salary-statements/details'
  import SalaryStatementForm from '@/modules/salary-statements/form'
  import {hasDialog, hasFilter} from '@/mixins'

  export default {
    name: 'salary-statement-list',

    mixins: [hasFilter, hasDialog],

    data() {
      return {
        activeDetailId: null,
        activeDetailDefaultDate: null,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '70'},
          {text: 'Дата', value: 'name'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      salaryStatements() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove', 'toggleState']),
      ...mapMutations(['setModel']),

      showDetailsDialog(item) {
        this.activeDetailId = item.id

        let statementDate = moment(item.date)
        if (statementDate.get('month') === moment().get('month')) {
          this.activeDetailDefaultDate = moment().format(SERVER_DATE_FORMAT)
        } else {
          this.activeDetailDefaultDate = statementDate.endOf('month').format(SERVER_DATE_FORMAT)
        }

        this.detailDialog = true
      },

      closeDetails() {
        this.detailDialog = false
        this.activeDetailId = null
        this.activeDetailDefaultDate = null
        this.setModel({})
      },

      showForm(item) {
        if (item && item.id) {
          this.show(item.id)
        } else {
          this.setModel({})
        }
        this.formDialog = true
      },

      toggleStateHandler(item) {
        if (!item.is_closed && confirm(`Вы уверены закрыть ведомость №${item.id}`)) {
          this.toggleState(item.id)
              .then(() => {
                this.fetch(this.filter)
              })
        }
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Зарплатные ведемости')
    },

    components: {
      SalaryStatementDetail,
      SalaryStatementForm,
    }
  }
</script>
