<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-container fluid>
        <v-row ref="filtersContainer">
          <v-col cols="6" class="d-flex align-center">
            <span class="text-h5"><v-icon large color="black" class="pb-1">mdi-bank-transfer</v-icon> Конверсия</span>
          </v-col>
        </v-row>
        <div v-if="fetchingBalances" class="ma-10">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-row v-else>
          <v-col cols="3">
            <v-card color="indigo lighten-3">
              <v-card-title class="font-weight-bold py-2">Наличные</v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  Всего: {{accountBalances.cash_total|money}}
                </v-list-item>
                <v-list-item>
                  В сумах: {{accountBalances.cash_uzs_total|money}}
                </v-list-item>
                <v-list-item>
                  В долларах: {{accountBalances.cash_usd_total|money}}
                </v-list-item>
              </v-list>
            </v-card>
            <v-card color="indigo lighten-3" class="mt-4">
              <v-card-title class="font-weight-bold py-2">Перечисление</v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                  <v-list-item>Всего: {{accountBalances.transfer_total|money}}</v-list-item>
                  <v-list-item>В сумах: {{accountBalances.transfer_usd_total|money}}</v-list-item>
                  <v-list-item>В долларах: {{accountBalances.transfer_uzs_total|money}}</v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="12 pt-2 text-h6">Форма конверсии</v-col>
              <v-col cols="3" class="deep-orange lighten-5">
                <v-select v-model="model.cash_type"
                          :items="PaymentForms" label="Форма платежа"
                          item-value="id" item-text="name"
                          required
                ></v-select>
              </v-col>
              <v-col cols="2" class="deep-orange lighten-5">
                <v-select v-model="model.currency"
                          :items="currencies" label="Валюта"
                          @input="setConversionCurrency"
                          item-value="id" item-text="title"
                          required
                ></v-select>
              </v-col>
              <v-col cols="2" class="text-center deep-orange lighten-5">
                <v-icon class="mt-6" large>mdi-swap-horizontal</v-icon>
              </v-col>
              <v-col cols="3" class="deep-orange lighten-5">
                <v-select v-model="model.conversion_payment.cash_type"
                          :items="PaymentForms" label="Форма платежа"
                          @input="setConversionCurrency"
                          item-value="id" item-text="name"
                          required
                ></v-select>
              </v-col>
              <v-col cols="2" class="deep-orange lighten-5">
                <v-select v-model="model.conversion_payment.currency"
                          :items="convertableCurrencies" label="Валюта"
                          item-value="id" item-text="title"
                          required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <currency-rate-toolbar class="mt-3"></currency-rate-toolbar>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model.number="model.total" :suffix="getCurrency(model.currency).format"
                              @input="setConversionTotal"
                              label="Списываемая сумма"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model.number="model.conversion_payment.total"
                              :suffix="getCurrency(model.conversion_payment.currency).format"
                              label="Зачисляемая сумма"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-if="model.currency === model.conversion_payment.currency && +model.total > +model.conversion_payment.total"
                  disabled :suffix="getCurrency(model.currency).format"
                  :value="model.conversion_payment.total - model.total"
                  label="Разница"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox v-model="model.conversion_payment.is_expenditure"
                            label="Сохранить разницу в расходах"
                            color="success" hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="model.comment"
                              label="Комментарий" hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="my-4">
                <v-btn @click="handleSubmit(save)"
                       :loading="!!converting"
                       :disabled="!!converting || invalid"
                       rounded color="success"
                ><v-icon left>mdi-content-save</v-icon> Конвертировать</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </validation-observer>
  </v-form>
</template>

<script>
import {createNamespacedHelpers, mapState as defaultMapState} from 'vuex'

const {mapState, mapActions, mapMutations} = createNamespacedHelpers('CurrencyRate')
import currencyRateToolbar from '@/modules/currency-rates/toolbar'
import {hasCurrency} from '@/mixins'

export default {
  name: 'currency-exchange',

  mixins: [hasCurrency],

  data() {
    return {
    }
  },

  computed: {
    convertableCurrencies() {
      return this.currencies.filter(c =>
        this.model.cash_type !== this.model.conversion_payment.cash_type || this.model.currency !== c.id
      )
    },

    ...mapState({
      converting: 'converting',
      current: 'current',
      model: 'conversion',
      accountBalances: 'accountBalances',
      fetchingBalances: 'fetchingBalances',
    }),
    ...defaultMapState('Payment', ['PaymentForms']),
  },

  methods: {
    setConversionCurrency() {
      this.$nextTick(() => {
        if (this.model.cash_type === this.model.conversion_payment.cash_type) {
          if (this.model.conversion_payment.currency === this.model.currency) {
            this.model.conversion_payment.currency = null
          }
        }
      })
    },

    setConversionTotal() {
      if (this.model.currency !== this.model.conversion_payment.currency) {
        if (this.model.currency === 'uzs') {
          this.model.conversion_payment.total = parseFloat(this.model.total / this.current.rate)
            .toFixed(this.getCurrency('usd').precision)
        } else {
          this.model.conversion_payment.total = this.model.total * this.current.rate
        }

      }
    },

    save() {
      this.$store.dispatch('CurrencyRate/conversion')
          .then(() => {
            this.$emit('exchange:form:saved')
            this.$eventBus.$emit('notification:success', 'Успешно!')
            this.$store.commit('CurrencyRate/setConversion', {})
            this.$store.dispatch('CurrencyRate/fetchBalance')
          })
    },
  },

  mounted() {
    this.$store.dispatch('CurrencyRate/fetchBalance')
    this.$eventBus.$emit('setTitle', 'Конверсия')
  },

  components: {
    currencyRateToolbar,
  },
}
</script>
