var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить платеж' : 'Создать платеж'))]),_c('v-divider'),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTypeSelector && !_vm.model.id),expression:"showTypeSelector && !model.id"}],staticClass:"text-center"},[_c('h2',{staticClass:"my-5"},[_vm._v("Выберите тип платежа")]),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"success","large":""},on:{"click":function($event){_vm.model.type = 'debet'; _vm.showTypeSelector = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download-multiple")]),_vm._v(" Приход ")],1),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"error","large":""},on:{"click":function($event){_vm.model.type = 'credit'; _vm.showTypeSelector = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload-multiple")]),_vm._v(" Расход ")],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTypeSelector || _vm.model.id),expression:"!showTypeSelector || model.id"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"reg_date","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"paymentDate",attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm._f("date")(_vm.model.reg_date),"label":"Дата","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.paymentDateMenu),callback:function ($$v) {_vm.paymentDateMenu=$$v},expression:"paymentDateMenu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.futureDates},on:{"input":function($event){_vm.paymentDateMenu = false}},model:{value:(_vm.model.reg_date),callback:function ($$v) {_vm.$set(_vm.model, "reg_date", $$v)},expression:"model.reg_date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!!_vm.model.id,"items":_vm.SourceTypes.getFor(_vm.model.type),"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Тип"},on:{"change":_vm.resetSourceId},model:{value:(_vm.model.source),callback:function ($$v) {_vm.$set(_vm.model, "source", $$v)},expression:"model.source"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[(_vm.model.source && _vm.objects.hasData)?_c('validation-provider',{attrs:{"name":"object","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":!!_vm.model.id,"items":_vm.objects.data,"loading":_vm.objects.loading,"item-value":"id","item-text":_vm.objects.title,"required":"","error-messages":errors,"label":_vm.objectLabel},model:{value:(_vm.object),callback:function ($$v) {_vm.object=$$v},expression:"object"}})]}}],null,true)}):_vm._e()],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.PaymentTypes,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Тип"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"cash_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.PaymentForms,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Форма платежа"},model:{value:(_vm.model.cash_type),callback:function ($$v) {_vm.$set(_vm.model, "cash_type", $$v)},expression:"model.cash_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.currencies,"label":"Валюта","item-value":"id","item-text":"format","required":"","error-messages":errors},on:{"change":_vm.setCrossCurrency},model:{value:(_vm.model.currency),callback:function ($$v) {_vm.$set(_vm.model, "currency", $$v)},expression:"model.currency"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"total","rules":"required|positive|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Сумма","error-messages":errors,"required":""},on:{"input":_vm.setCrossAmount},model:{value:(_vm.model.total),callback:function ($$v) {_vm.$set(_vm.model, "total", $$v)},expression:"model.total"}})]}}],null,true)})],1),(_vm.isCrossCurrencyEnabled)?[_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"color":"success","label":"Кросс-валютная операция","hide-details":"","disabled":!_vm.isCrossCurrencyEnabled},on:{"change":_vm.setCrossRate},model:{value:(_vm.model.cross_currency),callback:function ($$v) {_vm.$set(_vm.model, "cross_currency", $$v)},expression:"model.cross_currency"}})],1),(_vm.model.cross_currency)?[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"cross_rate","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Курс обмена","suffix":_vm.getCurrency(_vm.model.cross_currency_data.cross_currency).format,"hint":_vm.currencyHint,"persistent-hint":""},on:{"input":_vm.setCrossAmount},model:{value:(_vm.model.cross_currency_data.rate),callback:function ($$v) {_vm.$set(_vm.model.cross_currency_data, "rate", $$v)},expression:"model.cross_currency_data.rate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"cross_currency_data_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm._f("money")(_vm.model.cross_currency_data.cross_currency_amount,_vm.model.cross_currency_data.cross_currency, true),"label":"Получаемая сумма","disabled":"","required":"","error-messages":errors,"suffix":_vm.getCurrency(_vm.model.cross_currency_data.cross_currency).format}})]}}],null,true)})],1)]:_vm._e()]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"comments","rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Комментарий"},model:{value:(_vm.model.comments),callback:function ($$v) {_vm.$set(_vm.model, "comments", $$v)},expression:"model.comments"}})]}}],null,true)})],1)],2)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать'))],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }